import React from "react";
import { NavLink } from "react-router-dom";
import { Logout } from "../Authetification/Logout";
import { helpFunc } from "../../helpers/helpFunction";
import { useAuth } from "../../contexts/AuthContext";
import { Alerts } from "../Alerts/Alerts";

export const Header = () => {
  const { authToken } = useAuth();

  return (
    <div className="layout-header">
      <div className="wrap">
        <div id="logo">
          <NavLink to="/">iPharm</NavLink>
          <span>({helpFunc.currentDate()})</span>
        </div>
        <div className="navigation">
          <Alerts />
          <div>
            <NavLink to="/help">Nápověda</NavLink>
          </div>
          {authToken && <Logout />}
        </div>
      </div>
    </div>
  );
};
