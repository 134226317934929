import React from "react";

export const Legend = () => {
  return (
    <div className="legend">
      <ul>
        <li>Nový PEN-FAST</li>
        <li>Zhodnocený PEN-FAST</li>
        <li>Zhodnocení + challenge</li>
      </ul>
    </div>
  );
};
