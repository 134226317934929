import React, { useState, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { BiSortAlt2 } from "react-icons/bi";
import { helpFunc } from "../../helpers/helpFunction";
import { PAGINATION_PER_PAGE_LIMIT } from "../../helpers/constants";
import { patientService } from "../../services/patient.services";

export const PenfastPatientsHospitalPastLogic = (careType) => {
  const [state, setState] = useState("");
  const [table, setTable] = useState({
    loading: false,
    message: "",
    pagination: { count: 0 },
  });
  const [filter, setFilter] = useState({});
  const [error, setError] = useState({ error: false });
  const [paramsState, setParamsState] = useState({
    has_current_penfast: true,
    is_active: false,
    current_care_type: careType,
    offset: 0,
    page: 1,
    limit: PAGINATION_PER_PAGE_LIMIT,
    ordering: localStorage.getItem("pen-fast-patient-hospital-past-ordering")
      ? localStorage.getItem("pen-fast-patient-hospital-past-ordering")
      : "",
  });

  // On component mount call released patients for last 30 days
  useEffect(() => {
    getDefaultReleasedPatients();
  }, []);

  const handleTableSort = (value) => {
    getPatients(value);
  };

  const handleResetFilter = () => {
    localStorage.removeItem("pen-fast-patient-hospital-past-filter");
    window.location.reload(false);
  };

  const localStorageFilter = JSON.parse(
    localStorage.getItem("pen-fast-patient-hospital-past-filter")
  );

  const getDefaultReleasedPatients = () => {
    const data = {
      filterOption: localStorageFilter?.filterOption,
      care_date_from: localStorageFilter?.care_date_from
        ? new Date(localStorageFilter.care_date_from)
        : helpFunc.getPriorDate(30),
      care_date_to: localStorageFilter?.care_date_to
        ? new Date(localStorageFilter.care_date_to)
        : helpFunc.getCurrentDate(),
      filterValue: localStorageFilter?.filterValue,
      filterOption: localStorageFilter?.filterOption,
      ageOption: localStorageFilter?.ageOption,
    };
    findPatients(data);
  };

  //FORM SECTION
  const methods = useForm({
    defaultValues: {
      care_date_from: localStorageFilter?.care_date_from
        ? new Date(localStorageFilter.care_date_from)
        : helpFunc.getPriorDate(30),
      care_date_to: localStorageFilter?.care_date_to
        ? new Date(localStorageFilter.care_date_to)
        : helpFunc.getCurrentDate(),
      filterValue: localStorageFilter?.filterValue
        ? localStorageFilter.filterValue
        : "",
      filterOption: localStorageFilter?.filterOption
        ? localStorageFilter.filterOption
        : [{ label: "Vyberte...", value: "" }],
      ageOption: localStorageFilter?.ageOption
        ? localStorageFilter.ageOption
        : { label: "Rovno", value: "equal" },
    },
  });

  //Options for filter select and age select
  const options = {
    filterOptions: [
      { label: "Rodné číslo", value: "birth_number" },
      { label: "Příjmení a jméno", value: "name" },
      { label: "Věk", value: "age" },
      { label: "ID pacienta", value: "id" },
    ],
    ageOptions: [
      { label: "Rovno", value: "equal" },
      { label: "Menší než", value: "smaller" },
      { label: "Větší než", value: "greater" },
    ],
  };

  //Watch if filters are being used
  const filterOption = useWatch({
    control: methods.control,
    name: "filterOption",
    defaultValue: localStorageFilter?.filterOption
      ? localStorageFilter.filterOption
      : false,
  });
  const ageOption = useWatch({
    control: methods.control,
    name: "ageOption",
    defaultValue: localStorageFilter?.ageOption
      ? localStorageFilter.ageOption
      : null,
  });

  useEffect(() => {
    controlFilter(filterOption);
  }, []);

  useEffect(() => {
    controlAgeFilter(ageOption);
  }, []);

  //Show option select when user pics age search
  useEffect(() => {
    controlFilter(filterOption);
  }, [filterOption]);

  useEffect(() => {
    controlAgeFilter(ageOption);
  }, [ageOption]);

  const controlAgeFilter = (ageOption) => {
    if (ageOption === null) {
      setFilter({ ...filter, filter: false });
    }
    if (filterOption.value === "age") {
      setFilter({ ...filter, filter: true, age: true });
    } else {
      setFilter({ ...filter, filter: true });
    }
  };

  const controlFilter = (filter) => {
    if (filter === null) {
      setFilter({ age: false, filter: false, numbers: true });
    } else if (filter.value === "name") {
      setFilter({ ...filter, filter: true, numbers: false });
    } else if (filter.value === "age") {
      setFilter({ ...filter, filter: true, age: true, numbers: true });
    } else {
      setFilter({ numbers: true, age: false, filter: true });
    }
  };

  //Get patients according to filters to options
  const findPatients = async (data, value) => {
    //date picker => start "" => when delete null
    data = helpFunc.replaceNull(data);
    localStorage.setItem(
      "pen-fast-patient-hospital-past-filter",
      JSON.stringify(data)
    );
    let params = {
      has_current_penfast: true,
      is_active: false,
      care_type: careType,
      ordering: paramsState.ordering,
      page: paramsState.page,
      offset: paramsState.offset,
      limit: PAGINATION_PER_PAGE_LIMIT,
    };
    if (data.filterOption?.value === "id") {
      try {
        setTable({ ...table, loading: true });
        let response = await patientService.getPatientsFilter({
          ...params,
          external_id: data.filterValue,
        });
        const pagination = { count: response.count };
        response = response.results;
        if (response.length === 0) {
          setError({ error: true, message: "Žádný pacient nebyl nalezen" });
          setTable({ data: response, loading: false });
        } else {
          setTable({
            ...table,
            loading: false,
            message: "",
            data: [
              {
                ...response[0],
                external_id: helpFunc.pStrToNum(response[0].external_id),
                age: helpFunc.countPatientAge(
                  response[0].age_display,
                  response[0].age_display_measure_unit
                ),
              },
            ],
            pagination,
          });
        }
      } catch (error) {
        console.error(error);
        setError({ error: true, message: "Chyba při vyhledávání pacienta." });
        setTable({ data: [], loading: false });
      }
    } else {
      //adding time to parameters
      if (data.care_date_from !== "") {
        const date = new Date(data.care_date_from);
        date.setUTCHours(0, 0, 0);
        params = {
          ...params,
          care_date_from: date,
        };
      }
      if (data.care_date_to !== "") {
        // adding 23h 23m 23s to the care ending date
        const date = new Date(data.care_date_to);
        date.setUTCHours(23, 59, 59);

        params = {
          ...params,
          care_date_to: date,
        };
      }

      //put together params form what I have
      if (data.filterOption?.value === "age") {
        switch (data.ageOption.value) {
          case "equal":
            params = { ...params, age: data.filterValue };
            break;
          case "smaller":
            params = { ...params, age__max: data.filterValue };
            break;
          case "greater":
            params = { ...params, age__min: data.filterValue };
            break;
        }
      } else {
        switch (data.filterOption?.value) {
          case "birth_number":
            params = { ...params, birth_number__contains: data.filterValue };
            break;
          case "name":
            params = { ...params, name__contains: data.filterValue };
            break;
        }
      }
      try {
        //call api
        setTable({ ...table, loading: true });
        let response = await patientService.getPatientsFilter({
          ...params,
          page: 1,
          offset: 0,
        });
        const pagination = { count: response.count };
        if (response.results.length === 0) {
          setError({ error: true, message: "Žádný pacient nebyl nalezen" });
          setTable({ data: response.results, loading: false, pagination });
        }
        const tableData = await pTableData(response.results);

        setParamsState({
          ...params,
          page: response.page,
          offset: response.offset,
        });
        setTable({ ...table, loading: false, data: tableData, pagination });
      } catch (erorr) {
        console.error(error);
        setError({ error: true, message: "Chyba při vyhledávání pacientů." });
        setTable({ data: [], loading: false });
      }
    }
  };

  const onPaginate = async (offset, page) => {
    try {
      //call api
      setTable({ ...table, loading: true });
      let response = await patientService.getPatientsFilter({
        ...paramsState,
        offset,
        page,
      });
      if (response.results.length === 0)
        setError({ error: true, message: "Žádný pacient nebyl nalezen" });
      const tableData = await pTableData(response.results);
      const pagination = { count: response.count };
      setParamsState({
        ...paramsState,
        page: response.page,
        offset: response.offset,
      });
      setTable({ ...table, loading: false, data: tableData, pagination });
    } catch (erorr) {
      console.error(error);
      setError({ error: true, message: "Chyba při vyhledávání pacientů." });
    }
  };

  const getPatients = async (value) => {
    let orderingValue = paramsState.ordering;
    if (value) {
      orderingValue = paramsState.ordering.includes("-") ? value : `-${value}`;
      localStorage.setItem(
        "pen-fast-patient-hospital-past-ordering",
        orderingValue
      );
    }
    try {
      setTable({ ...table, loading: true });
      let data = await patientService.getPatientsFilter({
        ...paramsState,
        ordering: orderingValue,
      });
      const pagination = {
        ...table.pagination,
        count: data.count,
      };

      setParamsState({
        ...paramsState,
        page: data.page,
        offset: data.offset,
        ordering: orderingValue,
      });

      data = await pTableData(data.results);
      setTable({ ...table, loading: false, data, pagination });
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const pTableData = async (data) => {
    return data.map((val) => {
      return {
        ...val,
        external_id: helpFunc.pStrToNum(val.external_id),
        age: helpFunc.countPatientAge(
          val.age_display,
          val.age_display_measure_unit
        ),
        diagnoses: "Není diagnóza",
        department: "Není oddělení",
        days_in_hospital: "Pacient propuštěn",
        last_dekurz: "Není poslení dekurz",
        doctor_name: "Nemá lékaře",
      };
    });
  };

  //TABLE SECTION
  const columns = useMemo(() => [
    {
      Header: () => (
        <>
          <span>ID pacienta</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("external_id")}
            />
          </span>
        </>
      ),
      accessor: "external_id",
    },
    {
      Header: () => (
        <>
          <span>Příjmení a jméno</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("name")}
            />
          </span>
        </>
      ),
      accessor: "name",
    },
    {
      Header: () => (
        <>
          <span>RČ</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("birth_number")}
            />
          </span>
        </>
      ),
      accessor: "birth_number",
    },
    {
      Header: () => (
        <>
          <span>Věk</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("age_display")}
            />
          </span>
        </>
      ),
      accessor: "age",
    },
    {
      Header: () => null,
      id: "expander",
      Cell: ({ row }) => (
        <>
          <i
            className="fas fa-info-circle mobile-visible ml-2 table-icon"
            {...row.getToggleRowExpandedProps()}
          ></i>
          <button
            type="button"
            className="button desktop-visible"
            {...row.getToggleRowExpandedProps()}
          >
            Info o pacientovi
          </button>
        </>
      ),
    },

    { Header: "", accessor: "more_information" },
  ]);

  //Handle erorr popup
  const togglePopup = () => {
    setError({ error: false });
  };

  return {
    state,
    error,
    table,
    columns,
    options,
    methods,
    filter,
    togglePopup,
    findPatients,
    onPaginate,
    handleResetFilter,
    paramsState,
  };
};
