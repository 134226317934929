import { useState, useEffect } from "react";
import { userService } from "../../services/user.services";
import { FaRegCaretSquareDown, FaRegCaretSquareUp } from "react-icons/fa";

export const PredefinedTextModal = ({ close, methods }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getPredefinedText();
  }, []);

  const getPredefinedText = async () => {
    const response = await userService.getAllPredefinedText();
    if (response.status === 200) {
      setData(response.data.results);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value.length === 0) {
      getPredefinedText();
      return;
    }
    const filterData = data.filter((item) => item.name.includes(value));
    if (filterData) {
      setData(filterData);
    }
  };

  const setPredefinedText = (text) => {
    methods().setValue("text", text);
    methods().setValue("texts", text);
    close(false);
  };

  return (
    <div className="modal flex-container-column">
      <div className=" predefined-text-modal-content">
        <div className="predefined-text-modal-header">
          <h1>
            <i className="fas fa-search"></i> Předdefinované texty
          </h1>
          <i
            className="fas fa-times"
            style={{ cursor: "pointer" }}
            onClick={() => close(false)}
          ></i>
        </div>
        <input
          type="text"
          className="inp-text mt-3"
          placeholder="Název textu"
          onChange={(event) => handleInputChange(event)}
        />
        <table className="table table-sm table-striped table-bordered text-left mt-3">
          <tbody>
            {data.map((item) => (
              <TableRow
                key={item.id}
                item={item}
                setPredefinedText={setPredefinedText}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TableRow = ({ item, setPredefinedText }) => {
  const [expand, setExpand] = useState(false);
  return (
    <tr key={item.id}>
      <td style={{ width: "30%", color: "#02385d", fontWeight: "bold" }}>
        {item.name} | {item.is_own ? "vlastní" : "obecný"}
      </td>
      <td style={{ width: "60%" }}>
        <div className="predefined-text-modal-table-text">
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: expand
                ? item.text
                : item.text.length > 200
                ? `${item.text.slice(0, 200)}...`
                : item.text,
            }}
          ></div>
          {!expand ? (
            <FaRegCaretSquareDown onClick={() => setExpand(true)} />
          ) : (
            <FaRegCaretSquareUp onClick={() => setExpand(false)} />
          )}
        </div>
      </td>
      <td className="text-right" style={{ width: "10%" }}>
        <button className="button" onClick={() => setPredefinedText(item.text)}>
          Vybrat
        </button>
      </td>
    </tr>
  );
};
