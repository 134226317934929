import { useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import { others } from "../../services/other.services";
import { useAuth } from "../../contexts/AuthContext";
import { NavLink } from "react-router-dom";

export const Alerts = () => {
  const [alerts, setAlerts] = useState([]);
  const { authToken } = useAuth();

  useEffect(() => {
    if (!authToken) return;

    const getAlerts = async () => {
      try {
        const response = await others.getAlerts();
        if (response.status === 200) {
          setAlerts(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch alerts:", error);
      }
    };

    getAlerts(); // Initial fetch

    const interval = setInterval(() => {
      getAlerts();
    }, 30000); // Fetch every 30 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [authToken]); // Re-run effect when authToken changes

  if (!authToken) {
    return null;
  }

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <NavLink to="/pen-fast/present/hospitalization">
        <FaBell
          size={18}
          style={
            alerts[0]?.state === "red_warning"
              ? { color: "red" }
              : { color: "#ffffff" }
          }
        />
      </NavLink>
      {alerts[0]?.details?.not_evaluated_penfast_count > 0 && (
        <span
          style={{
            position: "absolute",
            top: "12px",
            right: "20px",
            color: "white",
            fontSize: "10px",
          }}
        >
          {alerts[0]?.details?.not_evaluated_penfast_count}
        </span>
      )}
    </div>
  );
};
