import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import Switch from "react-switch";

export const RadioSwitchComp = ({
  name,
  label,
  title,
  disabled = false,
  num = 0,
  classLabel,
  inputGroup,
  toggleSection = null,
  value,
  ...props
}) => {
  const { control, setValue, watch } = useFormContext();
  const selectedValue = watch(name); // Sleduje aktuálnu hodnotu

  const switchStyle = {
    offColor: "#cdd2d6",
    onColor: "#06c102",
    uncheckedIcon: false,
    checkedIcon: false,
    activeBoxShadow: "0 0 2px 3px #06c102",
    height: 24,
    width: 36,
    handleDiameter: 18,
  };

  return (
    <div className={`grid-column col-${num} ${inputGroup}`}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Switch
            checked={selectedValue === value} // Zapne sa len, ak sa hodnota zhoduje
            onChange={() => setValue(name, value)} // Nastaví hodnotu v RHF
            {...switchStyle}
            {...props}
          />
        )}
      />
      <span className={`${classLabel} formSubTitle`}>{label}</span>
    </div>
  );
};
