import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { patientService } from "../../services/patient.services";
import { helpFunc } from "../../helpers/helpFunction";
import { PAGINATION_PER_PAGE_LIMIT } from "../../helpers/constants";
import { BiSortAlt2 } from "react-icons/bi";

const externalId = [
  "external_id",
  "external_id__contains",
  "external_id__endswith",
  "external_id__startswith",
];
const age = ["age_display", "age_display__max", "age_display__min"];
const birthNumber = [
  "birth_number",
  "birth_number__contains",
  "birth_number__startswith",
  "birth_number__endswith",
];
const patientName = [
  "name",
  "name__contains",
  "name__startswith",
  "name__endswith",
];
const diagnosisName = [
  "current_care_diagnosis_name",
  "current_care_diagnosis_name__contains",
  "current_care_diagnosis_name__startswith",
  "current_care_diagnosis_name__endswith",
];

const departmentDescription = [
  "current_care_department_description",
  "current_care_department_description__contains",
  "current_care_department_description__endswith",
  "current_care_department_description__startswith",
];
const careDays = [
  "current_care_days",
  "current_care_days__max",
  "current_care_days__min",
];
const lastDekurz = [
  "current_care_last_dekurz_date",
  "current_care_last_dekurz_date__max",
  "current_care_last_dekurz_date__min",
];
const doctorName = [
  "current_care_doctor_name",
  "current_care_doctor_name__contains",
  "current_care_doctor_name__endswith",
  "current_care_doctor_name__startswith",
];
const careStartedAt = [
  "current_care_started_at",
  "current_care_started_at__max",
  "current_care_started_at__min",
];
const category = ["current_care_category"];

const defaultFilter = {
  ...externalId.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...age.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...birthNumber.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...patientName.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...diagnosisName.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...departmentDescription.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...careDays.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...lastDekurz.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...doctorName.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...careStartedAt.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...category.reduce((a, v) => ({ ...a, [v]: undefined }), {}),

  has_checkin: undefined,
  risk_level: undefined,
  require_attention: undefined,

  noCheckin: false,
  hasCheckin: false,

  riskLevelThree: false,
  riskLevelOne: false,
  riskLevelTwo: false,
  riskLevelNone: false,
};

export const PenfastPatientsHospitalPresentLogic = (careType) => {
  const [error, setError] = useState("");
  const [table, setTable] = useState({ loading: true });
  const [options, setOptions] = useState({ category: [] });
  const [paramsState, setParamsState] = useState({
    has_current_penfast: true,
    is_active: true,
    current_care_type: careType,
    offset: localStorage.getItem("pen-fast-patient-hospital-present-page")
      ? Number(
          localStorage.getItem("pen-fast-patient-hospital-present-page")
        ) === 1
        ? 0
        : Number(
            localStorage.getItem("pen-fast-patient-hospital-present-page")
          ) *
            PAGINATION_PER_PAGE_LIMIT -
          PAGINATION_PER_PAGE_LIMIT
      : 0,
    page: localStorage.getItem("pen-fast-patient-hospital-present-page")
      ? localStorage.getItem("pen-fast-patient-hospital-present-page")
      : 1,
    limit: PAGINATION_PER_PAGE_LIMIT,
    ordering: localStorage.getItem("pen-fast-patient-hospital-present-ordering")
      ? localStorage.getItem("pen-fast-patient-hospital-present-ordering")
      : careType === "ambulation"
      ? "-current_care_started_at"
      : "-current_care_days",
  });

  const storageFilter = JSON.parse(
    localStorage.getItem("pen-fast-patient-hospital-present-filter")
  );

  const [filter, setFilter] = useState(
    storageFilter ? storageFilter : defaultFilter
  );

  const history = useHistory();

  //Load patient data on when page loads

  useEffect(() => {
    getPatients();
    getPatientCategories();
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "pen-fast-patient-hospital-present-filter",
      JSON.stringify(filter)
    );
  }, [filter]);

  const handleTableSort = (value) => {
    getPatients(value);
  };

  const handleDateChange = (event, field) => {
    resetTableFilterValues(field);

    setFilter((prevFilter) => ({
      ...prevFilter,
      [field]: event,
    }));
  };

  const handleFilterChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    resetTableFilterValues(name);

    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const handleCheckboxFilterChange = (name) => {
    resetCheckboxFilterValues(name);

    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: !filter[name],
    }));
  };

  const handleResetFilter = () => {
    localStorage.removeItem("pen-fast-patient-hospital-present-filter");
    localStorage.removeItem("pen-fast-patient-hospital-present-ordering");
    window.location.reload(false);
  };

  const findPatients = async () => {
    //date picker => start "" => when delete null
    let params = {
      is_active: true,
      current_care_type: careType,
      ordering: paramsState.ordering,
      page: paramsState.page,
      offset: paramsState.offset,
      limit: PAGINATION_PER_PAGE_LIMIT,
    };
    localStorage.setItem("pen-fast-patient-hospital-present-page", 1);
    const data = { ...filter };
    try {
      //call api
      setTable({ ...table, loading: true });
      let response = await patientService.getPatientsFilter({
        ...params,
        ...filterMapper(data),
        page: 1,
        offset: 0,
      });
      if (response.results.length === 0) {
        setError({ error: true, message: "Žádný pacient nebyl nalezen" });
        setTable({ ...table, data: response.results, loading: false });
      }
      const tableData = await pPatientData(response.results);
      const pagination = {
        ...table.pagination,
        count: response.count,
      };
      setParamsState({
        ...params,
        page: response.page,
        offset: response.offset,
      });
      setTable({ ...table, loading: false, data: tableData, pagination });
    } catch (erorr) {
      console.error(error);
      setError({ error: true, message: "Chyba při vyhledávání pacientů." });
      setTable({ ...table, data: [], loading: false });
    }
  };

  const onPaginate = async (offset, page) => {
    localStorage.setItem("pen-fast-patient-hospital-present-page", page);
    const data = { ...filter };
    try {
      //call api
      setTable({ ...table, loading: true });
      let response = await patientService.getPatientsFilter({
        ...paramsState,
        ...filterMapper(data),
        offset,
        page,
      });
      if (response.results.length === 0)
        setError({ error: true, message: "Žádný pacient nebyl nalezen" });
      const tableData = await pPatientData(response.results);
      const pagination = {
        count: response.count,
      };
      setParamsState({
        ...paramsState,
        page: response.page,
        offset: response.offset,
      });
      setTable({ ...table, loading: false, data: tableData, pagination });
    } catch (erorr) {
      console.error(error);
      setError({ error: true, message: "Chyba při vyhledávání pacientů." });
    }
  };

  //TABLE SECTION
  //Load patient as data for table
  const getPatients = async (value) => {
    let orderingValue = paramsState.ordering;
    if (value) {
      orderingValue = paramsState.ordering.includes("-") ? value : `-${value}`;
      localStorage.setItem(
        "pen-fast-patient-hospital-present-ordering",
        orderingValue
      );
    }

    const dataFilter = { ...filter };
    try {
      setTable({ ...table, loading: true });
      let data = await patientService.getPatientsFilter({
        ...paramsState,
        ordering: orderingValue,
        ...filterMapper(dataFilter),
      });
      const pagination = {
        ...table.pagination,
        count: data.count,
      };

      setParamsState({
        ...paramsState,
        page: data.page,
        offset: data.offset,
        ordering: orderingValue,
      });

      data = await pPatientData(data.results);

      setTable({ ...table, loading: false, data, pagination });
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  //Parse patient data for table
  const pPatientData = async (data) => {
    return Promise.all(
      data.map(async (patient) => {
        let activeCare = helpFunc.getActiveCare(patient.cares);
        activeCare = helpFunc.replaceNull(activeCare[0]);
        return {
          ...patient,
          cares: [activeCare],
          id: patient?.id,
          external_id: patient?.external_id,
          age: helpFunc.countPatientAge(
            patient?.age_display,
            patient.age_display_measure_unit
          ),
          diagnoses: activeCare?.main_diagnosis.name,
          days_in_hospital: patient?.current_care_days,
          last_dekurz: helpFunc.formatDate(activeCare?.last_dekurz?.made_at),
          last_dekurz_timestamp: new Date(
            activeCare?.last_dekurz?.made_at
          ).getTime(),
          doctor_name:
            careType === "ambulation"
              ? activeCare.ambulant_doctor.name || "Žádne informace"
              : activeCare?.last_dekurz?.doctor?.name || "Žádne informace",
          f_title:
            careType === "ambulation"
              ? activeCare.ambulant_doctor.f_title || ""
              : activeCare?.last_dekurz?.doctor?.f_title || "",
          l_title:
            careType === "ambulation"
              ? activeCare.ambulant_doctor.l_title || ""
              : activeCare?.last_dekurz?.doctor?.l_title || "",
          department: patient.current_care_last_dekurz_department_description,
          status: getPatientStatus(activeCare),
          category: activeCare?.category
            ? activeCare?.category.name
            : "Žádne informace",
          first_visit: helpFunc.formatDate(activeCare?.started_at),
          requisitions: activeCare.requisitions,
          planned_hospitalization: activeCare.planned_hospitalization,
        };
      })
    );
  };

  // //Find patient status for colorbased filtering of the table
  const getPatientStatus = (activeCare) => {
    if (
      activeCare.current_penfast.challenge !== "none" &&
      activeCare.current_penfast.is_evaluated
    )
      return "green";

    if (
      activeCare.current_penfast.challenge === "none" &&
      !activeCare.current_penfast.is_evaluated
    )
      return "red";
    if (activeCare.current_penfast.is_evaluated) return "white";
  };

  const getPatientCategories = async () => {
    const response = await patientService.getPatientCategories();
    response.unshift({ id: 0, name: "Vyberte" });
    setOptions({ category: response });
  };

  const resetTableFilterValues = (name) => {
    if (externalId.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        external_id: undefined,
        external_id__contains: undefined,
        external_id__startswith: undefined,
        external_id__endswith: undefined,
      }));
    }

    if (age.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        age_display: undefined,
        age_display__max: undefined,
        age_display__min: undefined,
      }));
    }

    if (birthNumber.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        birth_number: undefined,
        birth_number__contains: undefined,
        birth_number__startswith: undefined,
        birth_number__endswith: undefined,
      }));
    }

    if (patientName.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        name: undefined,
        name__contains: undefined,
        name__startswith: undefined,
        name__endswith: undefined,
      }));
    }

    if (diagnosisName.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_diagnosis_name__contains: undefined,
        current_care_diagnosis_name__startswith: undefined,
        current_care_diagnosis_name__endswith: undefined,
      }));
    }

    if (departmentDescription.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_department_description_description: undefined,
        current_care_department_description_description__contains: undefined,
        current_care_department_description_description__startswith: undefined,
        current_care_department_description_description__endswith: undefined,
      }));
    }

    if (careDays.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_days: undefined,
        current_care_days__max: undefined,
        current_care_days__min: undefined,
      }));
    }

    if (lastDekurz.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_last_dekurz_date: undefined,
        current_care_last_dekurz_date__max: undefined,
        current_care_last_dekurz_date__min: undefined,
      }));
    }

    if (careStartedAt.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_started_at: undefined,
        current_care_started_at__max: undefined,
        current_care_started_at__min: undefined,
      }));
    }

    if (doctorName.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_doctor_name_description: undefined,
        current_care_doctor_name_description__contains: undefined,
        current_care_doctor_name_description__startswith: undefined,
        current_care_doctor_name_description__endswith: undefined,
      }));
    }
  };

  const resetCheckboxFilterValues = (name) => {
    if (name === "noCheckin") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        hasCheckin: false,
        require_attention: undefined,
        riskLevelThree: false,
        riskLevelOne: false,
        riskLevelTwo: false,
        riskLevelNone: false,
        risk_level: undefined,
        has_checkin: false,
      }));
    }

    if (name === "hasCheckin") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        noCheckin: false,
        has_checkin: true,
      }));
    }

    if (name === "require_attention") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        noCheckin: false,
      }));
    }

    if (name === "riskLevelThree") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        riskLevelOne: false,
        riskLevelTwo: false,
        riskLevelNone: false,
        noCheckin: false,
      }));
    }

    if (name === "riskLevelTwo") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        riskLevelOne: false,
        riskLevelThree: false,
        riskLevelNone: false,
        noCheckin: false,
      }));
    }

    if (name === "riskLevelOne") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        riskLevelThree: false,
        riskLevelTwo: false,
        riskLevelNone: false,
        noCheckin: false,
      }));
    }

    if (name === "riskLevelNone") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        riskLevelThree: false,
        riskLevelTwo: false,
        riskLevelOne: false,
        noCheckin: false,
      }));
    }
  };

  //Load columns as headers for table
  const columns = [];
  columns["hospitalization"] = useMemo(() => [
    {
      Header: () => (
        <>
          <span>ID pacienta</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("external_id")}
            />
          </span>
        </>
      ),
      accessor: "external_id",
    },
    {
      Header: () => (
        <>
          <span>Příjmení a jméno</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("name")}
            />
          </span>
        </>
      ),
      accessor: "name",
    },
    {
      Header: () => (
        <>
          <span>RČ</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("birth_number")}
            />
          </span>
        </>
      ),
      accessor: "birth_number",
    },
    {
      Header: () => (
        <>
          <span>Věk</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("age_display")}
            />
          </span>
        </>
      ),
      accessor: "age",
    },
    {
      Header: () => (
        <>
          <span>DG</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_diagnosis_name")}
            />
          </span>
        </>
      ),
      accessor: "diagnoses",
    },
    {
      Header: () => (
        <>
          <span>Oddělení</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleTableSort(
                  "current_care_last_dekurz_department_description"
                )
              }
            />
          </span>
        </>
      ),
      accessor: "department",
    },
    {
      Header: () => (
        <>
          <span>Počet dnů hospitalizace</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_days")}
            />
          </span>
        </>
      ),
      accessor: "days_in_hospital",
    },
    {
      Header: () => (
        <>
          <span>Datum pos. dekurzu</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_last_dekurz_date")}
            />
          </span>
        </>
      ),
      accessor: "last_dekurz_timestamp",
      Cell: ({ row }) => row.original.last_dekurz,
    },
    {
      Header: () => (
        <>
          <span>Jméno lékaře</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_doctor_name")}
            />
          </span>
        </>
      ),
      accessor: "doctor_name",
      Cell: ({ row }) =>
        `${row.original?.f_title} ${row.original?.doctor_name} ${row.original?.l_title}`,
    },
    {
      Header: () => null,
      id: "expander",
      Cell: ({ row }) => (
        <>
          <button
            type="button"
            className="button desktop-visible"
            onClick={() => openPatient(row)}
          >
            Otevřít pacienta
          </button>
          <i
            style={{ color: "#ffffff" }}
            className="fas fa-user-injured mobile-visible mr-2 table-icon"
            onClick={() => openPatient(row)}
          ></i>
          <i
            className="fas fa-info-circle mobile-visible ml-2 table-icon"
            {...row.getToggleRowExpandedProps()}
            style={{ color: "#ffffff" }}
          ></i>
          <button
            type="button"
            className="button desktop-visible"
            {...row.getToggleRowExpandedProps()}
          >
            Info o pacientovi
          </button>
        </>
      ),
    },
    { Header: "", accessor: "more_information" },
    //{ Header: "Status", accessor: "status" },
  ]);

  columns["ambulation"] = useMemo(() => [
    {
      Header: () => (
        <>
          <span>ID pacienta</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("external_id")}
            />
          </span>
        </>
      ),
      accessor: "external_id",
    },
    {
      Header: () => (
        <>
          <span>Příjmení a jméno</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("name")}
            />
          </span>
        </>
      ),
      accessor: "name",
    },
    {
      Header: () => (
        <>
          <span>RČ</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("birth_number")}
            />
          </span>
        </>
      ),
      accessor: "birth_number",
    },
    {
      Header: () => (
        <>
          <span>Věk</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("age")}
            />
          </span>
        </>
      ),
      accessor: "age",
    },
    {
      Header: () => (
        <>
          <span>DG</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_diagnosis_name")}
            />
          </span>
        </>
      ),
      accessor: "diagnoses",
    },
    {
      Header: () => (
        <>
          <span>Oddělení</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleTableSort(
                  "current_care_last_dekurz_department_description"
                )
              }
            />
          </span>
        </>
      ),
      accessor: "department",
    },

    {
      Header: () => (
        <>
          <span>Datum návštěvy ambulance</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_started_at")}
            />
          </span>
        </>
      ),
      accessor: "first_visit",
    },
    {
      Header: () => (
        <>
          <span>Jméno lékaře</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_doctor_name")}
            />
          </span>
        </>
      ),
      accessor: "doctor_name",
      Cell: ({ row }) =>
        `${row.original?.f_title} ${row.original?.doctor_name} ${row.original?.l_title}`,
    },
    {
      Header: () => (
        <>
          <span>Kategorie</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_category")}
            />
          </span>
        </>
      ),
      accessor: "category",
    },
    {
      Header: () => null,
      id: "expander",
      Cell: ({ row }) => (
        <>
          <button
            type="button"
            className="button desktop-visible"
            onClick={() => openPatient(row)}
          >
            Otevřít pacienta
          </button>
          <i
            style={{ color: "#ffffff" }}
            className="fas fa-user-injured mobile-visible mr-2 table-icon"
            onClick={() => openPatient(row)}
          ></i>
          <i
            className="fas fa-info-circle mobile-visible ml-2 table-icon"
            {...row.getToggleRowExpandedProps()}
            style={{ color: "#ffffff" }}
          ></i>
          <button
            type="button"
            className="button desktop-visible"
            {...row.getToggleRowExpandedProps()}
          >
            Info o pacientovi
          </button>
        </>
      ),
    },
    { Header: "", accessor: "more_information" },
    { Header: "Status", accessor: "status" },
  ]);

  //Direct user to when clicking on open patient button in table
  const openPatient = (row) => {
    history.push(
      `/patient-profile/${row.original.id}/care/${row.original.cares[0].id}/pen-fast`
    );
  };

  const togglePopup = () => {
    setError({ error: false });
  };

  return {
    table,
    columns,
    options,
    error,
    findPatients,
    onPaginate,
    togglePopup,
    paramsState,
    filter,
    handleFilterChange,
    handleCheckboxFilterChange,
    handleDateChange,
    handleResetFilter,
  };
};

const filterMapper = (obj) => {
  const maped = obj;
  for (const key in maped) {
    if (maped[key] === undefined || maped[key] === "" || maped[key] === null) {
      delete maped[key];
    }
  }

  if (maped.current_care_category == 0) delete maped.current_care_category;

  if (maped.require_attention === false) {
    maped.require_attention = undefined;
  }

  let risk_level = undefined;
  if (maped.riskLevelOne) risk_level = "1";
  if (maped.riskLevelTwo) risk_level = "2";
  if (maped.riskLevelThree) risk_level = "3";
  if (maped.riskLevelNone) risk_level = "none";

  maped.risk_level = risk_level;

  let has_checkin = undefined;
  if (maped.hasCheckin) has_checkin = true;
  if (maped.noCheckin) has_checkin = false;

  maped.has_checkin = has_checkin;

  if (maped.current_care_last_dekurz_date)
    maped.current_care_last_dekurz_date = helpFunc.parseDate(
      maped.current_care_last_dekurz_date
    );
  if (maped.current_care_last_dekurz_date__max)
    maped.current_care_last_dekurz_date__max = helpFunc.parseDate(
      maped.current_care_last_dekurz_date__max
    );
  if (maped.current_care_last_dekurz_date__min)
    maped.current_care_last_dekurz_date__min = helpFunc.parseDate(
      maped.current_care_last_dekurz_date__min
    );

  if (maped.current_care_started_at)
    maped.current_care_started_at = helpFunc.parseDate(
      maped.current_care_started_at
    );
  if (maped.current_care_started_at__max)
    maped.current_care_started_at__max = helpFunc.parseDate(
      maped.current_care_started_at__max
    );
  if (maped.current_care_started_at__min)
    maped.current_care_started_at__min = helpFunc.parseDate(
      maped.current_care_started_at__min
    );

  delete maped.noCheckin;
  delete maped.hasCheckin;
  delete maped.riskLevelThree;
  delete maped.riskLevelOne;
  delete maped.riskLevelTwo;
  delete maped.riskLevelNone;
  return maped;
};
