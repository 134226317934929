import { FormProvider } from "react-hook-form";
import { SwitchComp } from "../Form/SwitchComp";
import { PenfastFormLogic } from "./PenfastFormLogic";
import { Form } from "../Form/Form";
import { RadioSwitchComp } from "../Form/RadioSwitchComp";
import { PredefinedTextModal } from "./PredefinedTextModal";
import { TextAreaFormInp } from "../Form/TextAreaFormInp";

export const PenfastForm = ({ penfast }) => {
  const {
    methods,
    challengeTypeWatch,
    options,
    predefinedTtextModal,
    message,
    setPredefinedTextModal,
    predefinedTextSetter,
    handleSave,
  } = PenfastFormLogic(penfast);

  return (
    <FormProvider {...methods}>
      <Form style={{ backgroundColor: "rgb(249 249 249)", padding: 18 }}>
        <div className="form-row mb-2">
          <div className="form-column">
            <SwitchComp
              name="is_evaluated"
              label="Zhodnoceno klinickým farmaceutem"
              classLabel="strong"
              inputGroup="input-group switch-comp"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-column">
            <SwitchComp
              name="challenge_type"
              label="Provedena challenge"
              classLabel="strong"
              inputGroup="input-group switch-comp"
            />
            {challengeTypeWatch && (
              <>
                {options.map((option) => (
                  <RadioSwitchComp
                    key={option.value}
                    name="challenge"
                    label={option.label}
                    value={option.value}
                    classLabel="strong"
                    inputGroup="input-group switch-comp"
                  />
                ))}
              </>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-column">
            <span className="title">Poznámka k provedenému PEN-FAST testu</span>
            <div style={{ textAlign: "right" }}>
              <button
                style={{ width: 200 }}
                className="button"
                type="button"
                onClick={() => setPredefinedTextModal(true)}
              >
                Předdefinované texty
              </button>
            </div>

            {predefinedTtextModal && (
              <PredefinedTextModal
                close={setPredefinedTextModal}
                methods={predefinedTextSetter}
              />
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-column">
            <TextAreaFormInp id="note" name="note" />
          </div>
        </div>
        {message && (
          <div className="form-row">
            <div className="form-column">
              <b>{message}</b>
            </div>
          </div>
        )}
        <div className="form-row-button">
          <button
            type="button"
            className="button"
            onClick={methods.handleSubmit(handleSave)}
          >
            Uložit
          </button>
        </div>
      </Form>
    </FormProvider>
  );
};
