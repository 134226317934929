import React from "react";
import { NavLink } from "react-router-dom";
import {
  PageNavigation,
  PenfastPatientsHospitalPresent,
  PenfastPatientsHospitalPast,
  PermissionDenied,
} from "../../components";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../contexts/AuthContext";

export const PenfastPatientsHospital = ({ match, ...props }) => {
  const { state, careType } = useParams();
  const { checkLicense } = useAuth();

  if (!checkLicense("penfast")) {
    return <PermissionDenied />;
  }

  return (
    <div>
      <h1>Pacienti s PEN-FASTem</h1>

      <PageNavigation className="sub-page-navigation present">
        <div>
          <NavLink
            to={`/pen-fast/present/${careType}`}
            className={(isActive) => (!isActive ? "" : "active")}
          >
            Aktuálně hospitalizovaní pacienti
          </NavLink>
        </div>
        <div>
          <NavLink
            to={`/pen-fast/past/${careType}`}
            className={(isActive) => (!isActive ? "" : "active")}
          >
            Propuštění pacienti
          </NavLink>
        </div>
      </PageNavigation>
      {state == "present" ? (
        <PenfastPatientsHospitalPresent careType={careType} />
      ) : (
        <PenfastPatientsHospitalPast careType={careType} />
      )}
    </div>
  );
};
