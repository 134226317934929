import { useAuth } from "../../contexts/AuthContext";
import { CareProceduresPharmaPlan } from "../CareProcedures/CareProceduresPharmaPlan";
import { ColorStripe } from "../Graphics/ColorStripe";
import { PermissionDenied } from "../PermissionDenied/PermissionDenied";
import { Table } from "../Table/Table";
import { PenfastForm } from "./PenfastForm";
import { PenfastLogic } from "./PenfastLogic";

export const Penfast = ({ patient, care, careID, checkin }) => {
  const { table, columns, patientRiskLevel, getPatientData } = PenfastLogic(
    patient,
    care,
    checkin
  );

  const penfasts = patient.current_care.penfasts;

  const { checkLicense } = useAuth();

  if (!checkLicense("penfast")) {
    return <PermissionDenied />;
  }

  const careType = care.care_type;

  return (
    <>
      <div className="patient-profile pharmacological-plan">
        <div className="myContent">
          <div className="grid-row">
            <div className="grid-column">
              <Table
                data={table.data}
                columns={columns}
                pharmaPlan
                careType={careType}
                getPatientData={getPatientData}
              />
              <ColorStripe className={`color-stripe-${patientRiskLevel}`} />
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-column flex-2 penfast mt-3">
              {penfasts.map((penfast) => (
                <PenfastForm key={penfast.id} penfast={penfast} />
              ))}
            </div>
            {/* RIGHT COLUMN */}
            <div className="grid-column flex-1">
              <div className="grid-row">
                {/* Medical procedures table */}
                <CareProceduresPharmaPlan
                  careId={careID}
                  reload={false}
                  careType={careType}
                />
              </div>
              {/* Note form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
