import React from "react";
import { NavLink } from "react-router-dom";
import {
  Anamneza,
  Checkin,
  PageNavigation,
  PatientInfo,
  RecordHistory,
} from "../../components";
import { PatientEntryControlLogic } from "./PatientEntryControlLogic";
import { useAuth } from "../../contexts/AuthContext";
import { PenfastForm } from "../../components/PenfastForm/PenfastForm";
import { Penfast } from "../../components/PenfastForm/Penfast";

export const PatientEntryControl = () => {
  const { patient, error, navigation, setNavigation, getPatient } =
    PatientEntryControlLogic();

  const { checkLicense } = useAuth();

  return (
    <>
      {patient.loading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <PageNavigation className="sub-page-navigation present m-0 mb-3">
            {checkLicense("height_and_weight") && (
              <div>
                <a
                  className={navigation === "anamneza" ? "active" : null}
                  onClick={() => setNavigation("anamneza")}
                >
                  Anamnéza
                </a>
              </div>
            )}
            <div>
              <a
                className={navigation === "entry-control" ? "active" : null}
                onClick={() => setNavigation("entry-control")}
              >
                Formulář vstupní kontroly
              </a>
            </div>
            {patient?.data.cares?.length > 1 && (
              <div>
                <a
                  className={navigation === "record-history" ? "active" : null}
                  onClick={() => setNavigation("record-history")}
                >
                  Historie záznamů
                </a>
              </div>
            )}
            {patient?.data.current_care?.penfasts !== 0 &&
              checkLicense("penfast") && (
                <div>
                  <a
                    className={navigation === "pen-fast" ? "active" : null}
                    onClick={() => setNavigation("pen-fast")}
                  >
                    PEN-FAST
                  </a>
                </div>
              )}
          </PageNavigation>
          {navigation === "anamneza" && (
            <Anamneza
              patient={patient.data}
              careID={patient.care.id}
              getPatientCallback={getPatient}
            />
          )}
          {navigation === "record-history" && (
            <div className="pharma-plan-goback-btn" style={{ marginTop: 30 }}>
              <NavLink
                to={`/patient-profile/${patient.data.id}/care/${patient.careID}/pharmacological-plan`}
              >
                <button type="button" className="button">
                  Zpět
                </button>
              </NavLink>
            </div>
          )}
          {navigation === "entry-control" && (
            <div className="patient-entry-control">
              {error && <h2>{error.message}</h2>}
              <PatientInfo
                patientData={patient.data}
                careID={patient.careID}
                careData={patient.care}
              />
              <div className="max-w-1024">
                <Checkin
                  patient={patient.data}
                  checkin={patient.care.checkin}
                  careID={patient.careID}
                  careIsLocked={false}
                />
              </div>
            </div>
          )}
          {navigation === "record-history" && (
            <RecordHistory
              patient={patient.data}
              care={patient.care}
              checkin={patient.care.checkin}
              careIsLocked={patient.care.is_locked}
              careID={patient.care.id}
              plan={patient.care.pharmacologicalplan}
              isPharmaPlan={false}
            />
          )}
          {navigation === "pen-fast" && (
            <Penfast
              patient={patient.data}
              care={patient.care}
              careID={patient.careID}
              checkin={patient.care.checkin}
            />
          )}
        </div>
      )}
    </>
  );
};
