import React from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export const Home = () => {
  const { checkLicense } = useAuth();
  return (
    <div>
      <div className="signpost">
        <div className="item">
          <NavLink to="/patients-beds">
            <span className="icon">
              <i className="fas fa-bed"></i>
            </span>
            <span className="name">
              Hospitalizovaní
              <br /> pacienti
            </span>
          </NavLink>
        </div>
        <div className="item">
          <NavLink to="/patients-visits">
            <span className="icon">
              <i className="fas fa-user-injured"></i>
            </span>
            <span className="name">
              Ambulantní
              <br /> pacienti
            </span>
          </NavLink>
        </div>
        <div className="item">
          <NavLink to="/patients-external/present">
            <span className="icon">
              <i className="far fa-id-card"></i>
            </span>
            <span className="name">
              Externí
              <br /> pacienti
            </span>
          </NavLink>
        </div>
        <div className="item">
          <NavLink to="/requisitions/hospitalization">
            <span className="icon">
              <i className="fa fa-clipboard" aria-hidden="true"></i>
            </span>
            <span className="name">
              Nezpracované
              <br /> žádanky
            </span>
          </NavLink>
        </div>
        {checkLicense("penfast") && (
          <div className="item">
            <NavLink to="/pen-fast/present/hospitalization">
              <span className="icon">
                <i className="fas fa-comment-medical" aria-hidden="true"></i>
              </span>
              <span className="name">PEN-FAST</span>
            </NavLink>
          </div>
        )}
        <div className="item">
          <NavLink to="/reports">
            <span className="icon">
              <i className="fas fa-chart-line"></i>
            </span>
            <span className="name">
              Statistiky
              <br /> a reporty
            </span>
          </NavLink>
        </div>
        <div className="item">
          <NavLink to="/settings">
            <span className="icon">
              <i className="fas fa-cog"></i>
            </span>
            <span className="name">
              Nastavení
              <br /> aplikace
            </span>
          </NavLink>
        </div>
      </div>
      <div className="sidebar"></div>
    </div>
  );
};
