import React from "react";
import { FormProvider } from "react-hook-form";
import {
  DatePicker,
  ControlSelect,
  TextInput,
  Table,
  PopUp,
  Form,
  NumInput,
  Pagination,
} from "../../components";
import { PenfastPatientsHospitalPastLogic } from "./PenfastPatientsHospitalPastLogic";

export const PenfastPatientsHospitalPast = ({ careType }) => {
  const {
    state,
    error,
    methods,
    options,
    columns,
    table,
    filter,
    togglePopup,
    findPatients,
    onPaginate,
    handleResetFilter,
    paramsState,
  } = PenfastPatientsHospitalPastLogic(careType);

  return (
    <div className="patient-hospital patient-past">
      <div className="max-w-1524">
        <div className="myContent">
          {error.error && (
            <PopUp toggle={togglePopup} message={error.message} />
          )}
          <FormProvider {...methods}>
            <Form>
              <div className="form-row" style={{ marginBottom: 10 }}>
                <div className="col-12 strong mb-2">VYHLEDAT DLE | DATUM</div>
                <div className="col-md-12 col-lg-6">
                  <DatePicker
                    labelClass="strong"
                    label="Od:"
                    name="care_date_from"
                  />
                </div>
                <div className="col-md-12 col-lg-6">
                  <DatePicker
                    name="care_date_to"
                    labelClass="strong"
                    label="Do:"
                  />
                </div>
              </div>
              <div
                className="form-row patient-past-select"
                style={{ marginBottom: 10 }}
              >
                <div className="col-12 strong mb-2">VYHLEDAT PACIENTY</div>
                <div
                  className={`col-md-12 ${
                    filter.age ? "col-lg-4" : "col-lg-6"
                  }`}
                >
                  <ControlSelect
                    label="DLE:"
                    labelClass="strong"
                    options={options.filterOptions}
                    name="filterOption"
                  />
                </div>
                {filter.age && (
                  <div
                    className={`col-md-12 ${
                      filter.age ? "col-lg-4" : "col-lg-6"
                    }`}
                  >
                    <ControlSelect
                      options={options.ageOptions}
                      name="ageOption"
                    />
                  </div>
                )}
                {filter.numbers ? (
                  <div
                    className={`patient-past-select col-md-12 ${
                      filter.age ? "col-lg-4" : "col-lg-6"
                    }`}
                  >
                    <NumInput
                      name="filterValue"
                      placeholder="Zadejte číselnou hodnotu"
                      disabled={!filter.filter}
                    />
                  </div>
                ) : (
                  <div
                    className={`patient-past-select col-md-12 ${
                      filter.age ? "col-lg-4" : "col-lg-6"
                    }`}
                  >
                    <TextInput
                      name="filterValue"
                      placeholder="Zadejte hodnotu"
                      disabled={!filter.filter}
                    />
                  </div>
                )}
              </div>
              <div className="form-row-button" style={{ paddingBottom: 10 }}>
                <button
                  type="submit"
                  className="button"
                  disabled={table.loading}
                  onClick={methods.handleSubmit(findPatients)}
                >
                  Vyhledat
                </button>
                <button
                  type="submit"
                  className="button"
                  onClick={handleResetFilter}
                >
                  Resetovat filtr
                </button>
              </div>
            </Form>
          </FormProvider>

          <div className="grid-row">
            <div className="container-fluid">
              <div className="row">
                {/* Results */}
                {table.loading ? (
                  <div className="col-12">
                    <h2>Načítání...</h2>
                  </div>
                ) : (
                  <>
                    <div className="col-12">
                      <Table
                        data={table.data || []}
                        columns={columns}
                        id={"penfast"}
                        status="past"
                      />
                    </div>
                    <div className="col-12">
                      <Pagination
                        onSubmit={onPaginate}
                        pagination={table.pagination}
                        paramsState={paramsState}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <span>{table.message}</span>
        </div>
      </div>
    </div>
  );
};
