import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { careServices } from "../../services/care.services";

export const PenfastFormLogic = (penfast) => {
  const [predefinedTtextModal, setPredefinedTextModal] = useState(false);
  const [message, setMessage] = useState(undefined);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      is_evaluated: penfast.is_evaluated,
      challenge: penfast.challenge,
      note: penfast.note,
      challenge_type: penfast.challenge !== "none" ? true : false,
    },
  });

  const handleSave = async (data) => {
    try {
      const response = await careServices.updatePenfast(data, penfast.id);
      if (response.status === 200) {
        setMessage(`PEN-FAST ID ${penfast.id} byl úspěšně uložen.`);
        setTimeout(() => {
          setMessage(undefined);
        }, 3000);
      }
    } catch (error) {
      setMessage(`PEN-FAST ID ${penfast.id} se nepovedlo uložit.`);
      setTimeout(() => {
        setMessage(undefined);
      }, 3000);
    }
  };

  const challengeTypeWatch = useWatch({
    control: methods.control,
    name: "challenge_type",
  });

  const options = [
    { label: "Negativní test (pacient bez reakce)", value: "negative" },
    { label: "Nejasný test (problémy s interpretací testu)", value: "unclear" },
    {
      label: "Pozitivní test (pacient s projevy alergické reakce)",
      value: "positive",
    },
  ];

  useEffect(() => {
    if (challengeTypeWatch === false) methods.setValue("challenge", "none");
  }, [challengeTypeWatch]);

  const predefinedTextSetter = () => {
    return methods;
  };

  return {
    methods,
    challengeTypeWatch,
    options,
    predefinedTtextModal,
    message,
    setPredefinedTextModal,
    predefinedTextSetter,
    handleSave,
  };
};

const schema = yup.object().shape({});
