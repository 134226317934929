import { useEffect, useMemo, useState } from "react";
import { helpFunc } from "../../helpers/helpFunction";

export const PenfastLogic = (patient, care, checkin) => {
  const [table, setTable] = useState({ loading: true, data: [] });

  useEffect(() => {
    getPatientData(patient, care);
  }, []);

  const getPatientData = async (patient, care) => {
    if (patient) {
      try {
        setTable({ ...table, loading: true });
        let data = await helpFunc.pPatientData(patient);
        data = {
          ...data,
          main_diagnosis: care?.main_diagnosis?.name || "Žádná",
          last_dekurz:
            helpFunc.formatDate(care?.last_dekurz?.made_at) || "Žádné datum",
          doctor: helpFunc.pDoctorName(care?.last_dekurz?.doctor),
          clinic: care?.clinic?.abbreviation || "Chybí data o klinice",
          department:
            patient.current_care_last_dekurz_department_description ||
            "Chybí data o oddělení",
          first_visit: helpFunc.formatDate(patient.current_care.started_at),
          ambulant_doctor: care?.ambulant_doctor
            ? `${care?.ambulant_doctor.f_title} ${care?.ambulant_doctor.name} ${care?.ambulant_doctor.l_title}`
            : "Žádné informace",
          external_doctor: care?.doctor || "Žádné informace",
        };
        setTable({ ...table, loading: false, data: [data] });
      } catch (e) {
        raiseError("Chyba při načítání informací o pacientovi.");
      }
    } else {
      setTable({ ...table, loading: false, data: null });
    }
  };

  const getPatientRiskLevel = (checkin = {}) => {
    switch (checkin?.risk_level) {
      case "1":
        return "blue";
      case "2":
        return "orange";
      case "3":
        return "red";
      case "none":
        return "none";
    }
  };
  const patientRiskLevel = getPatientRiskLevel(checkin);

  const columns = useMemo(() => [
    {
      Header: "Informace o pacientovi",
      columns: [
        {
          Header: "Příjmení a jméno pacienta",
          accessor: "name",
        },
        {
          Header: "Datum narození",
          accessor: "birth_date",
        },
        {
          Header: "Diagnóza",
          accessor: "main_diagnosis",
        },
        {
          Header: "Hmotnost",
          accessor: "weight",
        },
        {
          Header: "Výška",
          accessor: "height",
        },
        {
          Header: "BMI",
          accessor: "bmi",
        },
        {
          Header: "Rodné číslo",
          accessor: "birth_number",
        },
        {
          Header: "ID pacienta",
          accessor: care.care_type !== "external" ? "external_id" : "id",
        },
        {
          Header: "Dnů hospitalizace (k dnešnímu dni)",
          accessor: "days",
          show: care.care_type === "hospitalization",
        },

        {
          Header:
            care?.care_type === "hospitalization"
              ? "Datum posledního dekurzu"
              : "Datum návštěvy ambulance",
          accessor:
            care?.care_type === "hospitalization"
              ? "last_dekurz"
              : "first_visit",
        },
        {
          Header:
            care?.care_type === "hospitalization"
              ? "Ošetřující lékař"
              : "Jméno lékaře",
          accessor:
            care?.care_type === "external"
              ? "external_doctor"
              : care?.care_type === "ambulation"
              ? "ambulant_doctor"
              : "doctor",
        },
        {
          Header:
            care?.care_type !== "external"
              ? "Klinika"
              : "Zdravotnické zařízení",
          accessor: "clinic",
        },
        {
          Header: "Oddělení",
          accessor: "department",
        },
      ],
    },
  ]);

  return {
    table,
    columns,
    patientRiskLevel,
    getPatientData,
  };
};
